import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import './introduction.scss';

function Introduction (props) {

  useEffect(() => {
    function handleResize() {
      checkScrollableContent();
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => { 
      window.removeEventListener('resize', handleResize);
      hideArrowOption();
    }
  }, [] );

  const checkScrollableContent = () => {
    let content = document.querySelector('.panel-content');
    let arrowDown = document.querySelector('.arrow-option');

    if (arrowDown && content) {
      if (content.clientHeight < content.scrollHeight) {
        arrowDown.classList.add('visible');
      } else {
        arrowDown.classList.remove('visible');
      }
    }
  }

  const hideArrowOption = () => {
    let arrowDown = document.querySelector('.arrow-option')
    if (arrowDown) {
      arrowDown.classList.remove('visible');
    }
  }

  return (
    <div className="introduction">
      <div
        className="screen-text-header ng-binding">
        <h3>Términos del Servicio</h3>
        <p>Antes de utilizar la prueba, lea los Términos del Servicio. Recuerde que:</p> 
        <ul>
          <li>
            <strong>Esta plataforma no es un diagnóstico.</strong> Esta plataforma le proporciona cierta información pero no equivale a una opinión médica cualificada. Los posibles resultados de esta plataforma se basan exclusivamente en directrices de la OMS y de los Centros para el Control y Prevención de Enfermedades (CDC por sus siglas en inglés) en relación al COVID-19 solamente.
          </li>
          <li>
            <strong>En caso de urgencia, llame a su número local de urgencias de inmediato.</strong> No siga usando esta plataforma. Usted necesita atención médica urgente.
          </li>
          <li>
            <strong>Sus datos están seguros.</strong> No se comparte con nadie.
          </li>
        </ul>
      </div>
      <FormControl component="fieldset">
        <FormGroup aria-label="position">
          <FormControlLabel
            value="end"
            control={<Checkbox checked={props.termsNConditions} onChange={props.termsChangeHandler} color="primary" />}
            label="He Leido y acepto los terminos y condiciones"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default Introduction;
