import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import BirthdaySelector from './BirthdaySelector'
import PatientDetail from './PatientDetail';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import './Patient.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Patient (props) {
  const classes = useStyles();

  let patient = props.patient;
  let section = props.section;
  let render;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    function handleResize() {
      checkScrollableContent();      
      if (!mounted) {
        setMounted(true);
      }
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => { 
      window.removeEventListener('resize', handleResize);
      hideArrowOption();
    }
  }, [mounted] );

  const checkScrollableContent = () => {
    let content = document.querySelector('.panel-content');
    let arrowDown = document.querySelector('.arrow-option');

    if (arrowDown && content) {
      if (content.clientHeight < content.scrollHeight) {
        arrowDown.classList.add('visible');
      } else {
        arrowDown.classList.remove('visible');
      }
    }
  }

  const hideArrowOption = () => {
    let arrowDown = document.querySelector('.arrow-option');
    if (arrowDown) {
      arrowDown.classList.remove('visible');
    }
  }

  if (mounted) {
    setTimeout(() => {
      checkScrollableContent();
    }, 50);
  }

  const changePropertyHandler = (property, event) => {
    let value = (event.target.value || '');

    if (property === 'phone') {
      value = value.replace(/[^0-9+\-()\s]/g, '');
    } else if (property === 'ci') {
      value = value.replace(/[^0-9]/g, '');
    }

    props.updatePatientProperty(property, value);
  }

  const onEnterKeyPressed = (event) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      props.onKeyEnter();
    }
    
  }

  if (section.view === 'patient_basic') {
    render = (
      <div className="simple-form">
        <p className="title">
          Datos Personales
        </p>
        <TextField
          value={patient.name}
          id="patient-name"
          label="Nombre(s)"
          variant="outlined"
          onChange={(event) => {
            props.updatePatientProperty('name', event.target.value);
          }}
          onKeyPress={onEnterKeyPressed}
        />
        <TextField
          value={patient.lastName}
          id="patient-lastname"
          label="Apellido(s)"
          variant="outlined"
          onChange={(event) => {
            props.updatePatientProperty('lastName', event.target.value);
          }}
          onKeyPress={onEnterKeyPressed}
        />
      </div>
    );
  } else if (section.view === 'gender') {
    render = (
      <div className="simple-form">
        <p className="title">
          ¿Cuál es tu sexo?
        </p>
        <div className="gender-container">
          <div
            onClick={() => props.updatePatientProperty('gender', 'female')}
            className="option">
            <img alt="mujer" src="/images/female-icon.svg"/>Mujer</div>
          <div
            onClick={() => props.updatePatientProperty('gender', 'male')}
            className="option">
            <img alt="hombre" src="/images/male-icon.svg"/>Hombre</div>
          
        </div>
      </div>
    );
  } else if (section.view === 'birthday') {
    render = (
      <div className="simple-form">
        <p className="title">
          ¿Cuál es tu fecha de nacimiento?
        </p>
        <BirthdaySelector
          updatePatientProperty={props.updatePatientProperty}
          patient={patient}/>
        {patient.age ? <div className="patient-age">{patient.age + ' años'}</div> : null}
      </div>
    );
  } else if (section.view === 'ci_phone') {
    render = (
      <div className="simple-form">
        <p className="title">
          Información Personal
        </p>
        <TextField
          value={patient.phone}
          id="patient-phone"
          key="patient-phone"
          label="Teléfono/Celular"
          variant="outlined"
          type="tel"
          onChange={(event) => {
            // props.updatePatientProperty('phone', event.target.value);
            changePropertyHandler('phone', event);
          }}
          onKeyPress={onEnterKeyPressed}
        />
        <TextField
          value={patient.ci}
          className="no-arrows"
          id="patient-ci"
          key="patient-ci"
          label="CI"
          type="number"
          variant="outlined"
          onChange={(event) => {
            // props.updatePatientProperty('ci', event.target.value);
            changePropertyHandler('ci', event);
          }}
          onKeyPress={onEnterKeyPressed}
        />
      </div>
    );
  } else if (section.view === 'insurance_data') {
    let policeUnits = patient.policeUnits || [];
    let policeUnitsRender = policeUnits.map((policeUnit, i) => {
      return <MenuItem key={"police-unit-" + i} value={policeUnit}>{policeUnit}</MenuItem>
    });
    render = (
      <div className="simple-form">
        <p className="title">
          Información Personal
        </p>
        {/* <TextField
          value={patient.address}
          id="patient-address"
          key="patient-address"
          label="Dirección"
          variant="outlined"
          onChange={(event) => {
            props.updatePatientProperty('address', event.target.value);
          }}
          onKeyPress={onEnterKeyPressed}
        /> */}
        <TextField
          value={patient.insuranceNumberId}
          id="patient-insuranceNumberId"
          key="patient-insuranceNumberId"
          label="Número de Asegurado"
          variant="outlined"
          onChange={(event) => {
            props.updatePatientProperty('insuranceNumberId', event.target.value);
          }}
          onKeyPress={onEnterKeyPressed}
        />
        <TextField
          value={patient.armyNumberId}
          id="patient-armyNumberId"
          key="patient-armyNumberId"
          label="Número de Escalafón"
          variant="outlined"
          onChange={(event) => {
            props.updatePatientProperty('armyNumberId', event.target.value);
          }}
          onKeyPress={onEnterKeyPressed}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="patient-police-battalon">Unidad *</InputLabel>
          <Select
            labelId="patient-police-unit"
            id="patient-month-select"
            value={patient.extraPoliceUnit}
            onChange={(event) => {
              props.updatePatientProperty('extraPoliceUnit', event.target.value);
            }}
          >
            {policeUnitsRender}
          </Select>
        </FormControl>
      </div>
    );
  } else if (section.view === 'verification') {
    render = (
      <div className="simple-form">
        <p className="title">
          Por favor, verifique sus datos antes de continuar.
        </p>
        <PatientDetail patient={patient}/>
        <p className="italic">Para editar la información vuelva atrás.</p>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={patient.verificatedData}
                  onChange={ (event) => {
                    props.updatePatientProperty('verificatedData', event.target.checked);
                  } }
                  color="primary" />
              }
              label="Confirmo que la información es correcta"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </div>
    )
  }

  return (
    <div className="patient">
      {render}
    </div>
  )
}


