export const WhatToDoConstants = {
  no_risk: [
    {
      title: 'Mantenga una higiene estricta',
      descriptions: [
        {
          text: 'Se recomienda encarecidamente que tome precauciones sencillas que puedan reducir su probabilidad de infectarse en el futuro o de propagar el virus:',
          type: 'text'
        },
        {
          text: [
            'Límpiese bien y con asiduidad las manos con una solución de limpieza de manos con alcohol o con agua y jabón durante al menos 20 segundos',
            'Evite tocarse los ojos, nariz y boca',
            'Cúbrase la boca y la nariz con el codo doblado o con un pañuelo al toser o estornudar',
            'Desinféctese las manos tras estornudar.',
            'No comparta sus tazas, platos, cubiertos ni otros utensilios del hogar con sus familiares.'
          ],
          type: 'list'
        }
      ]
    },
    {
      title: 'Mantenga el distanciamiento social',
      descriptions: [
        {
          text: [
            'Mantenga al menos 2 metros (6 pies) de distancia entre usted y los demás, aunque no muestren síntomas de COVID-19.',
            'Evite viajar, en especial si es una persona mayor o tiene diabetes o una enfermedad cardiaca o pulmonar.'
          ],
          type: 'list'
        }
      ]
    },
    {
      title: 'Controle su salud',
      descriptions: [
        {
          text: 'Recuerde que se le aconseja encarecidamente utilizar esta herramienta de diagnóstico en caso de que cambie cualquier circunstancia, como que surjan nuevos síntomas o entre en contacto con una persona infectada.',
          type: 'text'
        }
      ]
    }
  ],
  self_monitoring: [
    {
      title: 'Descanse y permanezca en casa',
      descriptions: [
        {
          text: 'Aunque los síntomas que presenta probablemente sean de otra enfermedad distinta a la COVID-19, convendría que se quedara en casa hasta recuperarse. Permanecer en casa no solo hará que se recupere antes, sino que es una medida de protección para otras personas, en especial ancianos y personas vulnerables a enfermedades y para los trabajadores sanitarios que actualmente tratan la enfermedad. Esta precaución también la recomiendan los centros de control de enfermedades y prevención y el Centro Europeo para la Prevención y el Control de las Enfermedades.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Lávese las manos a menudo',
      descriptions: [
        {
          text: 'Límpiese bien y con asiduidad las manos con una solución de limpieza con alcohol o con agua y jabón. Evite tocarse los ojos, nariz y boca.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Mantenga el distanciamiento social',
      descriptions: [
        {
          text: [
            'El distanciamiento social y físico son claves para detener la transmisión. Mantenga al menos 2 metros (6 pies) de distancia entre usted y otros, aunque no muestren síntomas de la COVID-19.',
            'Evite los lugares públicos, en especial si es una persona mayor o sufre diabetes o una enfermedad coronaria o pulmonar.'
          ],
          type: 'list'
        }
      ]
    },
    {
      title: 'Controle sus síntomas',
      descriptions: [
        {
          text: 'Controle su salud varias veces al día. Si es posible, llévese un termómetro a su lugar de aislamiento y tómese la temperatura cada dos horas. Repita este control si observa nuevos síntomas.',
          type: 'text'
        },
        {
          text: '¿Cuándo debe llamar al 112 o a su número de emergencias local?',
          type: 'text'
        },
        {
          text: [
            'Si tiene problemas para respirar',
            'Si tiene fiebre en aumento (más de 40 °C, 104 °F)',
            'Si su estado de salud empeora'
          ],
          type: 'text'
        },
        {
          text: 'Dígales que se está haciendo la prueba de la COVID-19. Lleve una mascarilla antes de que lleguen.',
          type: 'text'
        }
      ]
    }
  ],
  quarantine: [
    {
      title: 'Cuarentena',
      descriptions: [
        {
          text: 'Elija una habitación separada bien ventilada o, si no es posible una habitación separada individual, mantenga una distancia de al menos 2 metros (6 pies) de otros miembros del hogar y minimice el uso de espacios compartidos como una cocina o baño. Recuerde almacenar provisiones y medicamentos adecuados para el período de cuarentena. Para su comodidad, asegúrese de tener acceso a Internet, noticias y entretenimiento. Mientras está en cuarentena, la comunicación con familiares y amigos en el exterior es importante para mantener una buena salud mental durante lo que puede ser para algunos momentos difíciles. La cuarentena generalmente dura 14 días, a partir del último día de exposición al virus.',
          type: 'text'
        },
        {
          text: ' Si considera que no puede soportar personalmente las condiciones de cuarentena durante el período completo de 14 días, comuníquese con las autoridades locales de salud pública para obtener ayuda. Están listos y dispuestos a ayudarlo durante este período.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Tosa y estornude correctamente',
      descriptions: [
        {
          text: [
            'Cúbrase la boca y la nariz con un pañuelo cuando vaya a toser o estornudar.',
            'Tire el pañuelo usado a una papelera con una bolsa de plástico.',
            'Desinféctese las manos después con una solución limpiadora con base de alcohol (mínimo 60 %) o lávese las manos con agua y jabón durante al menos 20 segundos.'
          ],
          type: 'list'
        }
      ]
    },
    {
      title: 'Lávese las manos a menudo',
      descriptions: [
        {
          text: 'Sus manos son un vector para el coronavirus. Cada vez que se toque la cara, tosa en las palmas o vaya al baño, las manos estarán contaminadas y propagará el virus por todo lo que toque. Es crucial para la seguridad de sus miembros del hogar que se limpie las manos a menudo con agua y jabón o una solución limpiadora con alcohol.'
        }
      ]
    },
    {
      title: 'Controle sus síntomas',
      descriptions: [
        {
          text: 'Controle su salud varias veces al día. Si es posible, llévese un termómetro a su lugar de aislamiento y tómese la temperatura cada dos horas. Repita este control si observa nuevos síntomas.',
          type: 'text'
        },
        {
          text: '¿Cuándo debe llamar al 112 o a su número de emergencias local?',
          type: 'text'
        },
        {
          text: [
            'Si tiene problemas para respirar',
            'Si tiene fiebre en aumento (más de 40 °C, 104 °F)',
            'Si su estado de salud empeora'
          ],
          type: 'list'
        },
        {
          text: 'Dígales que se está haciendo la prueba de la COVID-19. Lleve una mascarilla antes de que lleguen.',
          type: 'text'
        }
      ]
    }
  ],
  isolation_call: [
    {
      title: 'Apártese de inmediato de las personas y mascotas de su casa',
      descriptions: [
        {
          text: 'Si es posible, aíslese en otra habitación, lejos de otros miembros de su hogar. Si esto no es posible, mantenga una distancia de al menos 2 metros (6 pies) de los demás y use una mascarilla quirúrgica protectora. Evite toda interacción con las mascotas domésticas. Para obtener instrucciones detalladas sobre cómo configurar un espacio de aislamiento, visite ',
          textLink: {
            text: 'Directrices de los centros de control de enfermedades y prevención.',
            link: 'https://espanol.cdc.gov/coronavirus/2019-ncov/index.html'
          },
          type: 'text'
        }
      ]
    },
    {
      title: 'Llame al centro de salud local',
      descriptions: [
        {
          text: 'Llame al número local de atención sanitaria para la COVID-19. En función del país, puede ser un teléfono que proporciona el Ministerio de Sanidad.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Controle sus síntomas',
      descriptions: [
        {
          text: 'Por favor controle su salud varias veces al día. Si es posible, mantenga un termómetro en su lugar de aislamiento y controle su temperatura cada dos horas. Repita este chequeo si nota algún síntoma nuevo.',
          type: 'text'
        },
        {
          text: '¿Cuándo debe llamar al 112 o a su número de emergencias local?',
          type: 'text'
        },
        {
          text: [
            'Si tiene problemas para respirar',
            'Si tiene fiebre en aumento (más de 40 °C, 104 °F)',
            'Si su estado de salud empeora'
          ],
          type: 'list'
        },
        {
          text: 'Dígales que se está haciendo la prueba de la COVID-19. Lleve una mascarilla antes de que lleguen.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Use una mascarilla',
      descriptions: [
        {
          text: 'Póngase la mascarilla siempre que alguien vaya a estar en la misma habitación o vehículo que usted. Póngasela antes de entrar en un centro médico. La gente que interactúa con usted debe llevar también mascarillas, especialmente si le cuesta respirar.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Mantenga una higiene estricta',
      descriptions: [
        {
          text: 'Se recomienda encarecidamente que tome precauciones sencillas que puedan reducir su probabilidad de infectarse en el futuro o de propagar el virus:',
          type: 'text'
        },
        {
          text: [
            'Límpiese bien y con asiduidad las manos con una solución de limpieza con alcohol o con agua y jabón durante al menos 20 segundos',
            'Evite tocarse los ojos, nariz y boca',
            'Evite tocarse los ojos, nariz y boca',
            'Cúbrase la boca y la nariz con el codo doblado o con un pañuelo al toser o estornudar',
            'Desinféctese las manos tras estornudar',
            'No comparta las tazas, platos, cubertería ni otros utensilios de cocina con la familia'
          ],
          type: 'list'
        }
      ]
    },
    {
      title: 'Limpie a menudo las superficies que suela tocar en casa',
      descriptions: [
        {
          text: 'Las superficies y objetos como pomos de puertas, accesorios de baño, inodoros, teléfonos, teclados, tabletas y mesillas de noche se contaminan fácilmente con el coronavirus. Límpielos regularmente con limpiadores domésticos para evitar transmitir la enfermedad a otros miembros del hogar. Preste especial atención al área del inodoro y a otros lugares que puedan tener residuos de líquidos corporales. Las pautas de limpieza se pueden encontrar ',
          textLink: {
            text: 'aquí.',
            link: 'https://espanol.cdc.gov/coronavirus/2019-ncov/daily-life-coping/checklist-household-ready.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fcommunity%2Fhome%2Findex.html'
          },
          type: 'text'
        }
      ]
    }
  ],
  call_doctor: [
    {
      title: 'Llame a un médico o al centro de salud',
      descriptions: [
        {
          text: 'Llame primero a su médico de cabecera y cuéntele sus síntomas. El médico le preguntará cuáles son sus síntomas actuales y por su historial médico, y podría pedirle que se acerque a la consulta para hacerle un chequeo.',
          type: 'text'
        },
        {
          text: 'Si sus síntomas parecen ser muy graves, empeoran repentinamente y está preocupado, diríjase al servicio de urgencias más cercano (llame con anticipación si es posible) o llame a un número para emergencias.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Lávese las manos a menudo',
      description: [
        {
          text: 'Límpiese bien y con asiduidad las manos con una solución de limpieza con alcohol o con agua y jabón. Evite tocarse los ojos, nariz y boca.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Mantenga el distanciamiento social',
      descriptions: [
        {
          text: [
            'El distanciamiento social y físico son claves para detener la transmisión. Mantenga al menos 2 metros (6 pies) de distancia entre usted y otros, aunque no muestren síntomas de la COVID-19.',
            'Evite los lugares públicos, en especial si es una persona mayor o sufre diabetes o una enfermedad coronaria o pulmonar.'
          ],
          type: 'list'
        }
      ]
    }
  ],
  isolation_ambulance: [
    {
      title: 'Apártese de inmediato de otras personas y mascotas de su casa',
      descriptions: [
        {
          text: 'Si es posible, aíslese en una habitación aparte, lejos de otros miembros de su hogar. Si no es posible, mantenga una distancia de al menos 2 metros de otros y lleve una mascarilla quirúrgica protectora. Evite toda interacción con mascotas del hogar.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Llame al número de emergencias',
      descriptions: [
        {
          text: 'Llame al número de emergencias de su país. Sus síntomas son graves y puede necesitar traslado a un centro médico.',
          type: 'text'
        }
      ]
    },
    {
      title: 'Use una mascarilla',
      descriptions: [
        {
          text: 'Póngase la mascarilla siempre que alguien vaya a estar en la misma habitación o vehículo que usted. Póngasela antes de entrar en una ambulancia o en un centro médico. La gente que interactúa con usted debe llevar también mascarillas, especialmente si le cuesta respirar.',
          type: 'text'
        }
      ]
    }
  ]
};