import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Months } from './MonthsConstantLabel';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function PatientDetail(props) {
  const classes = useStyles();
  let patient = props.patient;
  let birthdayExtended = {...patient.birthdayExtended};

  let birthday = Months[birthdayExtended.month - 1] + ' ' + birthdayExtended.day + ', ' + birthdayExtended.year;

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemText
          primary={
            <React.Fragment>
              Nombre(s):&nbsp;&nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {patient.name}  
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <React.Fragment>
              Apellido(s):&nbsp;&nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {patient.lastName}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="Sexo"
          secondary={patient.gender === 'male' ? 'Hombre': 'Mujer'}
        />
        <ListItemText
          primary="Fec. de Nac."
          secondary={birthday}
        />
      </ListItem>
      <Divider component="li" />
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="Tel/Cel:"
          secondary={patient.phone}
        />
        <ListItemText
          primary="CI"
          secondary={patient.ci}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <React.Fragment>
              Núm. de asegurado:&nbsp;&nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {patient.insuranceNumberId}  
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <React.Fragment>
              Núm. de escalafón:&nbsp;&nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {patient.armyNumberId}  
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <React.Fragment>
              Unidad:&nbsp;&nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {patient.extraPoliceUnit}  
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
