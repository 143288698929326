import React from 'react';
import './sidebar.scss';

const sidebar = (props) => {
  let sections = props.sections;
  let renderSections = [];

  const selectSection = (event, section) => {
    if (event) { 
      event.stopPropagation();
    }

    if (section.disabled || section.active || !section.visited) {
      return;
    }

    props.sectionChangeHandler(section)
  }

  let activeSection = '';

  renderSections = sections.map((section) => {
    let optionClasses = [];

    if (section.active) {
      optionClasses.push('active');
      activeSection = section.key;
    }

    if (section.disabled) {
      optionClasses.push('disabled');
    }

    if (section.visited) {
      optionClasses.push('visited');
    }

    return (
      <div
        className={optionClasses.join(' ')}
        key={section.key}
        onClick={(event) => selectSection(event, section)}>
        {section.label}
      </div>
    );
  });


  return (
    <div className="sidebar">
      {renderSections}
      <div className={"progress-mobile " + activeSection}></div>
    </div>
  )
}

export default sidebar;