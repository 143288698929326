import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Months } from './MonthsConstantLabel';

dayjs.extend(customParseFormat);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

window.dayjs = dayjs;

export default function BirthdaySelector(props) {
  let patient = props.patient;
  let birthdayExtended = {...patient.birthdayExtended};

  // let patientBirtday = {
  //   day: '',
  //   month: '',
  //   year: ''
  // };

  // if (birthday) {
  //   var date = dayjs(new Date(birthday));

  //   patientBirtday.day = date.get('day');
  //   patientBirtday.month = date.get('month');
  //   patientBirtday.year = date.get('year');
  // }

  const classes = useStyles();
  const [month, setMonth] = React.useState(birthdayExtended.month || '');
  const [day, setDay] = React.useState(birthdayExtended.day || '');
  const [year, setYear] = React.useState(birthdayExtended.year || '');

  var days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(
      <MenuItem key={"day-" + i} value={i}>{i}</MenuItem>
    )
  }

  var years = [];
  var thisYear = dayjs().year();
  // console.log(thisYear);

  for (let i = thisYear; i > 1900; i--) {
    years.push(
      <MenuItem key={"year-" + i} value={i}>{i}</MenuItem>
    )
  }

  var months = [];

  for (let i = 0; i < Months.length; i++) {
    months.push(
      <MenuItem key={"month-" + i} value={i + 1}>{Months[i]}</MenuItem>
    )
  }

  const handleChange = (event, section) => {
    if (section === 'month') {
      setMonth(event.target.value);
    } else if (section === 'day') {
      setDay(event.target.value);
    } else if (section === 'year') {
      setYear(event.target.value);
    }

    props.updatePatientProperty('birthday', event.target.value, section);
  }

  return (
    <div className="patient-birthday">
      <FormControl className={classes.formControl}>
        <InputLabel id="patient-month-picker">Mes</InputLabel>
        <Select
          labelId="patient-month-picker"
          id="patient-month-select"
          value={month}
          onChange={(event) => { handleChange(event,'month')}}
        >
          {months}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="patient-day-picker">Día</InputLabel>
        <Select
          labelId="patient-day-picker"
          id="patient-day-select"
          value={day}
          onChange={(event) => { handleChange(event,'day')}}
        >
          {days}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="patient-year-picker">Año</InputLabel>
        <Select
          labelId="patient-year-picker"
          id="patient-year-select"
          value={year}
          onChange={(event) => { handleChange(event,'year')}}
        >
          {years}
        </Select>
      </FormControl>
    </div>
  )
}

// export default birthdaySelector;
