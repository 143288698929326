import React from 'react';
import { WhatToDoConstants } from './WhatToDoConstants';
import WhatToDoItem from './WhatToDoItem';
import Divider from '@material-ui/core/Divider';

export default function WhatToDo (props) {
  let section = {...props.section};
  let result = {...section.result};
  let triage_level = result.triage_level || '';
  let render = [];

  if (WhatToDoConstants.hasOwnProperty(triage_level)) {
    let whatDoList = WhatToDoConstants[triage_level];

    for (let i = 0; i < whatDoList.length; i++) {
      let whatDo = whatDoList[i];

      render.push(
        <WhatToDoItem
          key={whatDo.title}
          whatDo={whatDo}/>
      );

      if (i < whatDoList.length - 1) {
        render.push(
          <Divider
            key={triage_level + '-' + i + '-divider'}
            variant="middle"/>
        )
      }
    }
  }

  return (
    <div className="whatToDo">
      <h3>¿Qué debo hacer?</h3>
      <Divider variant="middle"/>
      {render}
    </div>
  )
}
