import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SymptomInfo from './Symptoms-info';
import QuestionInfo from './Question-info';
import Divider from '@material-ui/core/Divider';

import './Symptoms.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function Symptoms (props) {
  const classes = useStyles();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    function handleResize() {
      checkScrollableContent();      
      if (!mounted) {
        setMounted(true);
      }
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => { 
      window.removeEventListener('resize', handleResize);
      hideArrowOption();
    }
  }, [mounted] );

  const checkScrollableContent = () => {
    let content = document.querySelector('.panel-content');
    let arrowDown = document.querySelector('.arrow-option');

    if (arrowDown && content) {
      if (content.clientHeight < content.scrollHeight) {
        arrowDown.classList.add('visible');
      } else {
        arrowDown.classList.remove('visible');
      }
    }
  }

  const hideArrowOption = () => {
    let arrowDown = document.querySelector('.arrow-option');
    if (arrowDown) {
      arrowDown.classList.remove('visible');
    }
  }

  if (mounted) {
    setTimeout(() => {
      checkScrollableContent();
    }, 50);
  }

  var section = props.section;
  var patient = props.patient;
  var evidence = patient.evidence || [];
  var evidenceMap = {};
  for (let i = 0; i < evidence.length; i++) {
    let item = evidence[i];
    evidenceMap[item.id] = item.choice_id;
  }

  var render;

  if (!section.loadingQuestion && section.questions.length > 0) {
    let question = section.questions[section.questionIndex - 1].question;

    if (question) {
      let items = [...question.items];
      let itemsRender = [];
      let type = question.type;
      let questionExplanation = question.explanation || '';

      let renderQuestionOptions;
      let renderQuestionExplanation;

      if (type === 'group_multiple') {
        if (question.explicit) {

          // let listItems = [];
          for (let i = 0; i < items.length; i++) {
            let item = items[i];  
            itemsRender.push(
              
              <ListItem 
                key={item.id}
                alignItems="flex-start"
                className="mandatory-item">
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {item.name}&nbsp;<SymptomInfo item={item}/>&nbsp;
                      </Typography>
                      
                    </React.Fragment>
                  }
                />
                <RadioGroup
                  edge="end"
                  row
                  key={item.id}
                  name={item.id}
                  value={evidenceMap[item.id]}
                  onChange={(event) => {
                    props.saveEvidence(item, event.target.value);
                  }}>
                  <FormControlLabel
                    value="present"
                    control={<Radio color="primary"/>} label="Si" />
                  <FormControlLabel
                    value="absent"
                    control={<Radio color="primary"/>} label="No" />
                </RadioGroup>
              </ListItem>
            );

            if (i < items.length - 1) {
              itemsRender.push(
                <Divider key={"divider-group-multiple" + i} variant="middle"/>
              )
            }
          }

          renderQuestionOptions = (
            <List className={classes.root}>
              {itemsRender}
            </List>
          )

        } else {
          for (let i = 0; i < items.length; i++) {
            let item = items[i];

            itemsRender.push(
              <FormControlLabel
                key={item.id}
                value="end"
                control={
                  <Checkbox
                    checked={evidenceMap[item.id] === 'present'}
                    onChange={(event) => {
                      // let value = event.target.checked;
                      props.saveEvidence(item, event.target.checked);
                    }}
                    color="primary" />
                  }
                label={
                  <span>
                    {item.name}
                    {item.explanation ? <SymptomInfo item={item}/> : null}
                  </span>
                }
                labelPlacement="end"
              />
            );

            if (i < items.length - 1) {
              itemsRender.push(
                <Divider key={"divider-group-multiple" + i} variant="middle"/>
              )
            }
          }

          renderQuestionOptions = (
            <FormGroup aria-label="position">
              {itemsRender}
            </FormGroup>
          )
        }
      } else if (type === 'group_single') {
        for (let i = 0; i < items.length; i++) {
          let item = items[i];

          itemsRender.push(
            <RadioGroup
              key={item.id}
              name={item.id}
              value={evidenceMap[item.id]}
              onChange={(event) => {
                // updateEvidenceGroupSimpleItemsHandler(item, event.target.value)
                props.saveEvidence(item, event.target.value);
              }}>
              <FormControlLabel
                value="present"
                control={<Radio color="primary"/>} label={item.name} />
            </RadioGroup>
          )
        }

        renderQuestionOptions = (
          <>{itemsRender}</>
        )
      } else if (type === 'single' && items.length === 1) {
        let item = items[0];

        renderQuestionOptions = (
          <div className="single-options">
            <div
              className={"single-option " + (evidenceMap[item.id] === 'present' ? 'selected-single-option' : '')}
              onClick={() => { props.saveEvidence(item, true); }}>
              <img alt="si" src="/images/yes_icon.svg"/>
              <span>Si</span>
            </div>
            <div
              className={"single-option " + (evidenceMap[item.id] === 'absent' ? 'selected-single-option' : '')}
              onClick={() => { props.saveEvidence(item, false); }}>
              <img alt="no" src="/images/cancel_icon.svg"/>
              <span>No</span>
            </div>
          </div>
        )
      }

      if (questionExplanation) {
        renderQuestionExplanation = (
          <div className="question-explanation-container">
            <QuestionInfo explanation={questionExplanation}/>
          </div>
        );
      }

      render = (
        <div>
          <p className="label-text-symptoms">
            {question.text}
          </p>
          <div>
            {renderQuestionOptions}
          </div>
          {renderQuestionExplanation}
        </div>
      )
    }
  }

  return (
    <div className="symptoms">
      {render}
    </div>
  )
}
