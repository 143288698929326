import React from 'react';
import Loading from '../../UI/Loading/Loading'

import './Result.scss';

const result = (props) => {
  let section = {...props.section};
  let result = {...section.result}
  
  let renderResult;

  if (!section.loadingResult) {
    var seriousResult;

    if (result.serious && result.serious.length) {
      let list = result.serious.map((item) => {
        return (
          <li key={item.common_name}>
            {item.common_name}
          </li>
        )
      })
      seriousResult = (
        <div className="result-serius-symptoms">
          <ul>
            {list}
          </ul>
        </div>
      )
    }

    renderResult = (
      <div className="result-container">
        <div className={"result-status " + result.triage_level}>
        </div>
        <div className="result-summary">
          <span>Recomendación</span>
          <h3 className="result-label">{result.label}</h3>
          <p className="result-descriptoin">
            {result.description}
          </p>
          {seriousResult}
          <p className="re-do-test">
            <span onClick={props.reDoTest}>¿Volver a realizar la evaluación?</span>  
          </p>
        </div>
      </div>
    )
  } else {
    renderResult = <Loading />
  }

  return (
    <div className={'result ' + (section.loadingResult ? 'loading-result' : '')}>
      {renderResult}
    </div>
  )
};

export default result;