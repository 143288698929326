import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function WhatToDoItem (props) {
  let whatDo = {...props.whatDo};
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  let descriptionItems = [];
  let descriptions = whatDo.descriptions || [];

  for (var i = 0; i < descriptions.length; i++) {
    let description = descriptions[i];

    if (description.type === 'text') {
      let text = description.text;

      if (description.textLink) {
        text = [
          <span key={description.text + 'link'}>{description.text}</span>,
          <a key={description.text + 'link-a'} href={description.textLink.link} target="_blank">{description.textLink.text}</a>
        ]
      }
      descriptionItems.push(
        <ListItem key={description.text} className={classes.nested}>
          <ListItemText primary={text} />
        </ListItem>
      )
    } else if (description.type === 'list' && description.text && description.text.length > 0) {
      let subList = [];
      for (let j = 0; j < description.text.length; j++) {
        let itemDesc = description.text[j];

        subList.push(
          <li key={"sub-list-key-" + itemDesc}>
            {itemDesc}
          </li>
        )
      }

      descriptionItems.push(
        <ListItem 
          key={description.text}
          className={classes.nested}>
          <ul>
            {subList}
          </ul>
        </ListItem>
      )
    }
  }

  return (
    <>
    <ListItem key={"button-collapse" + whatDo.title} button onClick={handleClick}>
      {open ? <ExpandLess /> : <ExpandMore />}
      <ListItemText primary={whatDo.title} />
    </ListItem>
    <Collapse key={"collapse-section-" + whatDo.title} in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {descriptionItems}
      </List>
    </Collapse>
    </>
  )
}
