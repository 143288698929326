import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import './SimpleModal.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {/* <Fade in={true}> */}
        <div className={classes.paper + ' modal-error'}>
          <h2 id="transition-modal-title">Mensaje de sistema</h2>
          <p id="transition-modal-description">Hubo un problema registrando su información, por favor, intente nuevamente.</p>
          <div className="modal-footer">
            <Button 
              className="green-button"
              variant="contained"
              color="primary"
              onClick={props.retry}
              >
              Reintentar
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={props.close}
              >
              Cancelar
            </Button>
          </div>
        </div>
      {/* </Fade> */}
    </Modal>
  );
}