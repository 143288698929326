// const equiv
const dataToPersist = [
  'name',
  'lastName',
  'gender',
  'birthday',
  'phone',
  'personID',
  'insuranceNumberId',
  'armyNumberId',
  'birthdayExtended',
  'ci',
  'age',
  'extraPoliceUnit'
]

export default class localStorage {
  persisteData (data) {
    let patient = {};

    for (var key in data) {
      if (data.hasOwnProperty(key) &&
        data[key] &&
        dataToPersist.indexOf(key) > -1) {
        if (key === 'personID') {
          patient['ci'] = data[key];
        } else {
          patient[key] = data[key];
        }
      }
    }

    window.localStorage.setItem('u_storage', JSON.stringify(patient))
  }

  getPersistedData() {
    let data = {};
    let persistedData = window.localStorage.getItem('u_storage');

    if (persistedData) {
      try {
        data = JSON.parse(persistedData);

        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (dataToPersist.indexOf(key) < 0) {
              delete data[key];
            }
          }
        }
      } catch(e) {
        data = {}
      }
    }

    return data;
  }
}