import React from 'react';
import { withStyles } from '@material-ui/core/styles';  

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import './Symptoms.scss';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'justify'
  }
}))(Tooltip);


const SymptomInfo = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(false);
  };
  

  const handleTooltipClick = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(!open);
  };

  return (
    
    <span>
      {props.item.explanation ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{props.item.name}</Typography>
                {props.item.explanation}
              </React.Fragment>
            }
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button className="button-container" onClick={handleTooltipClick}>
              <span className="info-explanation-icon"></span>
            </Button>
          </HtmlTooltip>
        </ClickAwayListener>
      ) : null}
    </span>
  )
}

export default SymptomInfo;
