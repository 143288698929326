import React from 'react';

import './Loading.scss';

const loading = () => {
  return (
    <div id="loading-container">
      <div className="load">
        <img
          src="/images/logo-smd.png"
          alt="loading-logo"
          className="icon-load"/>
      </div>
    </div>
  )
}

export default loading;
