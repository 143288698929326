import React from 'react'

import './header.scss';

const header = (props) => {
  let loading;

  if (props.uploadingStatus === 'uploading') {
    loading = <span className="loading"></span>
  }
  
  if (props.uploadingStatus === 'success') {
    loading = <span className="success-message">Información Registrada</span>
  }
  
  return (
    <div className="header">
      <img src="/images/smd-logo-reduced.png" alt="smd-logo"/>
      <span className="header-title">
        Evaluación COVID-19
        {loading}
      </span>
      <img className="pol-logo" src="/images/pol-110.png" alt="pol-110-logo"/>
      {/* <div>Español</div> */}
    </div>
  );
}

export default header;