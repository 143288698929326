import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
// import Modal from '@material-ui/core/Modal';

import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
import Connection from '../../services/connection';
import Localstorage from '../../services/localstorage';

import SimpleModal from '../UI/SimpleModal/SimpleModal';
import Introduction from './Introduction/Introduction';
import Patient from './Patient/Patient';
import Symptoms from './Symptoms/Symptoms';
import Result from './Result/Result';
import WhatToDo from './Result/WhatToDo';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './Layout.scss';

dayjs.extend(customParseFormat);


class Layout extends Component {
  localstorage = new Localstorage();

  constructor(props) {
    super(props);

    this.state = {
      connection: new Connection(),
      termsNConditions: false,
      cleanFlow: true,
      uploadingStatus: '',
      errorModal: false,
      patient: {
        name: '',
        lastName: '',
        gender: '',
        birthday: '',
        birthdayExtended: {
          day: '',
          month: '',
          year: ''
        },
        age: null,
        phone: '',
        ci: '',
        address: '',
        insuranceNumberId: '',
        armyNumberId: '',
        extraPoliceUnit: '',
        verificatedData: false,
        recoverDataFromStorage: false,
        evidence: [],
        policeUnits: []
      },
      sections: [
        {
          key: 'introduction',
          label: 'introducción',
          visited: true,
          disabled: false,
          active: true
        },
        {
          key: 'patient',
          label: 'Paciente',
          visited: false,
          disabled: true,
          active: false,
          view: 'patient_basic',
          viewIndex: 0,
          views: [
            'patient_basic',
            'gender',
            'birthday',
            'ci_phone',
            'insurance_data',
            'verification'
          ]
        },
        {
          key: 'symptoms',
          label: 'Síntomas',
          visited: false,
          disabled: true,
          active: false,
          questions: [],
          questionIndex: 0,
          loadingQuestion: false
        },
        {
          key: 'result',
          label: 'Resultado',
          visited: false,
          disabled: true,
          active: false,
          loadingResult: false,
          result: null
        }
      ],
      options: {
        previous: {
          visible: false,
          disabled: true
        },
        next: {
          visible: true,
          disabled: true
        },
        label: {
          visible: false,
          text: 'Seleccione una de las respuestas de arriba'
        }
      }
    };
  }

  componentDidMount() {
    this.state.connection.reviewCache();
  }

  sectionChangeHandler = (section) => {
    switch(section.key) {
      case 'introduction': {
        this.moveToIntroductionSection();
        break;
      }
      case 'patient': {
        this.moveToPatientSection();
        break;
      }
      case 'symptoms': {
        
        break;
      }
      case 'result': {
        
        break;
      }
      default: {
        break;
      }
    }
  }

  previousSectionHandler = () => {
    let sections = [...this.state.sections];
    let activeSectionIndex = sections.findIndex(section => section.active);
    let activeSection = {...sections[activeSectionIndex]};
    switch(activeSection.key) {
      case 'patient': {
        if (activeSection.viewIndex > 0) {
          this.moveBackwardPatientView();
        } else {
          this.moveToIntroductionSection();
        }
        break;
      }
      case 'symptoms': {
        if (activeSection.questions.length > 1) {
          this.removeLastQuestion();
        } else {
          this.moveToPatientSection(true);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  nextSectionHandler = () => {
    // debugger;
    let sections = [...this.state.sections]
    let activeSectionIndex = sections.findIndex(section => section.active);
    let activeSection = {...sections[activeSectionIndex]};
    

    switch(activeSection.key) {
      case 'introduction': {
        this.state.connection.reviewCache(() => {
          this.moveToPatientSection(false, true);
        });
        
        break;
      }
      case 'patient': {
        if (activeSection.viewIndex  < activeSection.views.length - 1) {
          this.moveForwardPatientView();
        } else {
          this.moveToSymptomsSection();
        }
        break;
      }
      case 'symptoms': {
        this.getDiagnosis();
        break;
      }
      default: {
        break;
      }
    }
  }

  moveToSection(sectionKey) {
    let sections = [...this.state.sections];

    let sectionIndex = sections.findIndex(section => section.key === sectionKey);
    // let introductionSection = {...sections[introductionSectionIndex]};

    if (sectionIndex > -1) {
      for (var i = 0; i < sections.length; i++) {
        let section = {...sections[i]};
        if (i < sectionIndex) {
          section.visited = true;
          section.disabled = false;
          section.active = false;
        }

        if (i === sectionIndex) {
          section.visited = true;
          section.disabled = true;
          section.active = true;
        }

        if (i > sectionIndex) {
          section.visited = false;
          section.disabled = true;
          section.active = false;
        }

        sections[i] = section;
      }
    }
    return sections;
  }

  clearIntroductionSection(visited, active) {
    let sections = [...this.state.sections];
    let sectionIndex = sections.findIndex(section =>  section.key === 'introduction');
    let section = {...sections[sectionIndex]};

    section.visited = !!visited;
    section.active = !!active;
    section.disabled = !visited;

    return {
      section: section,
      sectionIndex: sectionIndex
    };
  }

  clearPatientSection(visited, active, toLastPatientSection) {
    let sections = [...this.state.sections];
    let sectionIndex = sections.findIndex(section =>  section.key === 'patient');
    let section = {...sections[sectionIndex]};

    section.visited = !!visited;
    section.active = !!active;
    section.disabled = !visited;

    section.viewIndex = toLastPatientSection ? section.views.length - 1 : 0;
    section.view = section.views[section.viewIndex];

    return {
      section: section,
      sectionIndex: sectionIndex
    };
  }

  clearSymptomsSection(visited, active, loadingQuestion) {
    let sections = [...this.state.sections];
    let sectionIndex = sections.findIndex(section =>  section.key === 'symptoms');
    let section = {...sections[sectionIndex]};

    section.visited = !!visited;
    section.active = !!active;
    section.disabled = !visited;

    section.questions = [];
    section.questionIndex = 0;
    section.loadingQuestion = !!loadingQuestion;

    return {
      section: section,
      sectionIndex: sectionIndex
    };
  }

  clearResultSection(visited, active, loadingResult) {
    let sections = [...this.state.sections];
    let sectionIndex = sections.findIndex(section =>  section.key === 'result');
    let section = {...sections[sectionIndex]};

    section.visited = !!visited;
    section.active = !!active;
    section.disabled = !visited;

    section.loadingResult = !!loadingResult;
    section.result = null;

    return {
      section: section,
      sectionIndex: sectionIndex
    };
  }

  clearOptionsOnChangeSection(section) {
    let options = {...this.state.options};
    let previousOption = {...options.previous};
    let nextOption = {...options.next};
    let labelOption = {...options.label};

    switch(section.key) {
      case 'introduction': {
        previousOption.disabled = true;
        previousOption.visible = false;
        nextOption.disabled = !this.state.termsNConditions;
        nextOption.visible = true;
        labelOption.visible = false;
        break;
      }
      case 'patient': {
        previousOption.visible = true;
        previousOption.disabled = false;
        nextOption.visible = true;
        nextOption.disabled = true;
        labelOption.visible = false;
        break;
      }
      case 'symptoms': {
        previousOption.disabled = true;
        previousOption.visible = true;
        nextOption.disabled = true;
        nextOption.visible = true;
        labelOption.visible = false;
        break;
      }
      case 'result': {
        previousOption.visible = false;
        previousOption.disabled = false;
        nextOption.visible = false;
        nextOption.disabled = true;
        labelOption.visible = false;
        break;
      }
      default: {
        break;
      }
    }

    options.next = nextOption;
    options.previous = previousOption;
    options.label = labelOption;

    return options;
  }

  moveToIntroductionSection = () => {
    let sections = [...this.state.sections];
    let patient = {...this.state.patient};

    let introductionSectionInfo = this.clearIntroductionSection(true, true);
    let patientSectionInfo = this.clearPatientSection(false, false);
    let symptomsSectionInfo = this.clearSymptomsSection(false, false);
    let resultSectionInfo = this.clearResultSection(false, false);

    sections[introductionSectionInfo.sectionIndex] = introductionSectionInfo.section;
    sections[patientSectionInfo.sectionIndex] = patientSectionInfo.section;
    sections[symptomsSectionInfo.sectionIndex] = symptomsSectionInfo.section;
    sections[resultSectionInfo.sectionIndex] = resultSectionInfo.section;

    let options = this.clearOptionsOnChangeSection(introductionSectionInfo.section);
    patient.evidence = [];

    this.setState({
      sections: sections,
      options: options,
      patient: patient,
      uploadingStatus: '',
      errorModal: false
    });
  }

  moveToPatientSection(toLastPatientSection, fromIntroduction) {
    let sections = [...this.state.sections];
    let patient = {...this.state.patient};

    if (fromIntroduction && this.state.cleanFlow) {
      this.matchPatientDataFromStorage(patient);

      if (patient.recoverDataFromStorage) {
        toLastPatientSection = true;
      }
    }

    let introductionSectionInfo = this.clearIntroductionSection(true, false);
    let patientSectionInfo = this.clearPatientSection(true, true, toLastPatientSection);
    let symptomsSectionInfo = this.clearSymptomsSection(false, false);
    let resultSectionInfo = this.clearResultSection(false, false);

    let options = {...this.state.options};
    let nextOption = {...options.next};
    let previousOption = {...options.previous};
    let labelOption = {...options.label};

    sections[introductionSectionInfo.sectionIndex] = introductionSectionInfo.section;
    sections[patientSectionInfo.sectionIndex] = patientSectionInfo.section;
    sections[symptomsSectionInfo.sectionIndex] = symptomsSectionInfo.section;
    sections[resultSectionInfo.sectionIndex] = resultSectionInfo.section;

    previousOption.visible = true;
    previousOption.disabled = false;
    nextOption.visible = true;
    nextOption.disabled = true;
    labelOption.visible = false;

    options.next = nextOption;
    options.previous = previousOption;
    options.label = labelOption;
    patient.evidence = [];

    this.setState({
      sections: sections,
      options: options,
      patient: patient,
      cleanFlow: false,
      uploadingStatus: '',
      errorModal: false
    }, () => {
      this.checkNextPropertyEnableForPatient();
    })
  }

  matchPatientDataFromStorage(patient) {
    let patientStorageData = this.localstorage.getPersistedData();
    let updatedData = false;

    for (var key in patientStorageData) {
      if (patientStorageData.hasOwnProperty(key) &&
        patient.hasOwnProperty(key) &&
        patientStorageData[key]) {
        patient[key] = patientStorageData[key];
        updatedData = true;
      }
    }
    
    if (updatedData) {
      patient.recoverDataFromStorage = true;
    }
  }

  cleanPatientData() {
    let patient = {...this.state.patient};

    patient.name = '';
    patient.lastName = '';
    patient.gender = '';
    patient.birthday = '';
    patient.birthdayExtended = {
      day: '',
      month: '',
      year: ''
    };
    patient.age = null;
    patient.phone = '';
    patient.ci = '';
    patient.address = '';
    patient.insuranceNumberId = '';
    patient.armyNumberId = '';
    patient.verificatedData = false;
    patient.recoverDataFromStorage = false;
    patient.evidence = [];
    patient.extraPoliceUnit = '';

    return patient;
  }

  moveBackwardPatientView() {
    let sections = [...this.state.sections]
    let patientSectionIndex = sections.findIndex(section => section.key === 'patient');
    let patientSection = {...sections[patientSectionIndex]};
    let patient = {...this.state.patient};

    if (patient.recoverDataFromStorage) {
      patientSection.viewIndex = 0;
      patient = this.cleanPatientData();
    } else {
      patientSection.viewIndex--;
    }

    patientSection.view = patientSection.views[patientSection.viewIndex];

    sections[patientSectionIndex] = patientSection;

    this.setState({
      sections: sections,
      patient: patient
    }, () => {
      this.checkNextPropertyEnableForPatient();
    });
  }

  moveForwardPatientView() {
    let sections = [...this.state.sections]
    let patientSectionIndex = sections.findIndex(section => section.key === 'patient');
    let patientSection = {...sections[patientSectionIndex]};

    // let patient = {...this.state.patient};

    patientSection.viewIndex++;
    patientSection.view = patientSection.views[patientSection.viewIndex];

    sections[patientSectionIndex] = patientSection;

    this.setState({
      sections: sections
    }, () => {
      this.checkNextPropertyEnableForPatient();
    });
  }

  removeLastQuestion() {
    // console.log('patient.evidence', this.state.patient.evidence);

    let sections = [...this.state.sections]
    let symptomSectionIndex = sections.findIndex(section => section.key === 'symptoms');
    let symptomSection = {...sections[symptomSectionIndex]};

    let patient = {...this.state.patient};
    
    let evidence = [...patient.evidence];

    let latestQuestion = symptomSection.questions[symptomSection.questions.length - 1];
    
    if (latestQuestion && latestQuestion.question && latestQuestion.question.items && latestQuestion.question.items.length > 0) {
      let items = latestQuestion.question.items;
      let itemsIds = items.map(item => item.id);

      evidence = evidence.filter(evidenceItem => itemsIds.indexOf(evidenceItem.id) < 0);

      patient.evidence = evidence;
      symptomSection.questions.pop();
      symptomSection.questionIndex = symptomSection.questions.length;
      sections[symptomSectionIndex] = symptomSection;

      let options = {...this.state.options};
      let nextOption = {...options.next};

      nextOption.disabled = false;
      options.next = nextOption;

      this.setState({
        patient: patient,
        sections: sections,
        options: options
      });
    }
  }

  moveToSymptomsSection() {
    let sections = [...this.state.sections];

    let introductionSectionInfo = this.clearIntroductionSection(true, false);
    let patientSectionInfo = this.clearPatientSection(true, false);
    let symptomsSectionInfo = this.clearSymptomsSection(true, true, true);
    let resultSectionInfo = this.clearResultSection(false, false);

    let options = {...this.state.options};
    let nextOption = {...options.next};
    let previousOption = {...options.previous};
    let labelOption = {...options.label};

    sections[introductionSectionInfo.sectionIndex] = introductionSectionInfo.section;
    sections[patientSectionInfo.sectionIndex] = patientSectionInfo.section;
    sections[symptomsSectionInfo.sectionIndex] = symptomsSectionInfo.section;
    sections[resultSectionInfo.sectionIndex] = resultSectionInfo.section;
    
    previousOption.visible = true;
    previousOption.disabled = false;
    nextOption.visible = true;
    nextOption.disabled = true;
    labelOption.visible = false;

    options.next = nextOption;
    options.previous = previousOption;
    options.label = labelOption;

    this.setState({
      sections: sections,
      options: options,
      uploadingStatus: '',
      errorModal: false
    }, () => {
      this.getDiagnosis();
    });
  }

  saveEvidence = (item, value) => {
    let patient = {...this.state.patient};
    let evidence = [...patient.evidence];
    let evidenceIndex = evidence.findIndex(evidenceItem => evidenceItem.id === item.id);
    let evidenceItem = {...evidence[evidenceIndex]};

    let options = {...this.state.options};
    let nextOption = {...options.next};

    if (value && value !== 'absent') {
      evidenceItem.choice_id = 'present';
    } else {
      evidenceItem.choice_id = 'absent';
    }

    evidence[evidenceIndex] = evidenceItem;

    let sections = [...this.state.sections]
    let symtomsSectionIndex = sections.findIndex(section => section.key === 'symptoms');
    let symtomsSection = {...sections[symtomsSectionIndex]};

    let currentQuestion = symtomsSection.questions[symtomsSection.questions.length - 1];

    if (currentQuestion.question.type === 'group_single') {
      let items = (currentQuestion.question.items || []).filter((questionItem) => questionItem.id !== item.id);

      for (let i = 0; i < items.length; i++) {
        let questionItem = items[i];

        let questionItemIndex = evidence.findIndex(evidenceItem => evidenceItem.id === questionItem.id);
        let questionEvidenceItem = {...evidence[questionItemIndex]};

        if (currentQuestion.question.type !== 'single') {
          questionEvidenceItem.choice_id = 'absent';
        } else {
          questionEvidenceItem.choice_id = '';
        }
        
        evidence[questionItemIndex] = questionEvidenceItem;
      }

      nextOption.disabled = false;
      options.next = nextOption;
    } else if (currentQuestion.question.type === 'single' || currentQuestion.question.type === 'group_multiple') {
      let questionItems = [...currentQuestion.question.items];
      let itemsWithValues = 0;
      
      for (let i = 0; i < questionItems.length; i++) {
        let questionItem = questionItems[i];
        let questionItemIndex = evidence.findIndex(evidenceItem => evidenceItem.id === questionItem.id);
        let questionEvidenceItem = {...evidence[questionItemIndex]};

        if (questionEvidenceItem.choice_id) {
          itemsWithValues++;
        }
      }

      // if (itemsWithValues === questionItems.length) {

      // }
      nextOption.disabled = itemsWithValues !== questionItems.length;
      options.next = nextOption;
    }

    patient.evidence = evidence;

    this.setState({
      patient: patient,
      options: options
    });
  }

  moveToResultSection = () => {
    let sections = [...this.state.sections];

    let introductionSectionInfo = this.clearIntroductionSection(true, false);
    let patientSectionInfo = this.clearPatientSection(true, false);
    let symptomsSectionInfo = this.clearSymptomsSection(true, false);
    let resultSectionInfo = this.clearResultSection(true, true, true);

    sections[introductionSectionInfo.sectionIndex] = introductionSectionInfo.section;
    sections[patientSectionInfo.sectionIndex] = patientSectionInfo.section;
    sections[symptomsSectionInfo.sectionIndex] = symptomsSectionInfo.section;
    sections[resultSectionInfo.sectionIndex] = resultSectionInfo.section;

    let options = {...this.state.options};
    let nextOption = {...options.next};
    let previousOption = {...options.previous};

    nextOption.visible = false;
    previousOption.visible = false;

    options.next = nextOption;
    options.previous = previousOption;

    this.setState({
      sections: sections,
      options: options,
      uploadingStatus: '',
      errorModal: false
    }, () => {
      this.getTriage();
    });
  }

  getTriage() {
    let patient = {...this.state.patient};
    let data = {
      sex: patient.gender,
      age: patient.age,
      evidence: patient.evidence
    };

    this.state.connection.getTriage(data).then((response) => {
      // console.log('response', JSON.stringify(response, null, 2));
      // console.log('patient', JSON.stringify(patient, null, 2));
      // console.log('data', JSON.stringify(data, null, 2));
      // // console.log('patient', patient);
      // // console.log('data', data);
      
      let sections = [...this.state.sections]
      let resultSectionIndex = sections.findIndex(section => section.key === 'result');
      let resultSection = {...sections[resultSectionIndex]};

      resultSection.loadingResult = false;
      resultSection.result = ({...response}).data;

      sections[resultSectionIndex] = resultSection;

      this.setState({
        sections: sections
      }, () => {
        this.pushPatientDataIntoSMD();
      });
    })
  }

  pushPatientDataIntoSMD() {
    let patient = {...this.state.patient};
    let sections = [...this.state.sections]
    let resultIndex = sections.findIndex(section =>  section.key === 'result');
    let resultSection = {...sections[resultIndex]};

    var evidence = [...patient.evidence];

    evidence.push({id: '@patient.age', choice_id: patient.age})

    let data = {
      name: patient.name,
      lastName: patient.lastName,
      gender: patient.gender,
      birthday: patient.birthday,
      phone: patient.phone,
      personID: patient.ci,
      address: patient.address,
      insuranceNumberId: patient.insuranceNumberId,
      armyNumberId: patient.armyNumberId,
      evidence: evidence,
      triage: resultSection.result
    };

    let extraData = {
      birthdayExtended: {...patient.birthdayExtended},
      age: patient.age,
      extraPoliceUnit: patient.extraPoliceUnit
    };

    this.setState({
      uploadingStatus: 'uploading',
      errorModal: false
    }, () => {
      this.pushData(data, extraData, 0);
    });
  }

  pushData = (data, extraData, countRetries) => {
    if (countRetries < 2) {
      this.state.connection.submitToSMD(data, extraData).then(() => {
        this.localstorage.persisteData({...data, ...extraData});
        this.resetFullData();
        this.setState({
          uploadingStatus: 'success'
        });
      }, () => {
        setTimeout(() => {
          this.pushData(data, extraData, countRetries + 1);
        }, 1000);
      });
    } else {
      this.dataToSave = {
        data: JSON.parse(JSON.stringify(data)),
        extraData: JSON.parse(JSON.stringify(extraData))
      }

      this.setState({
        uploadingStatus: 'error',
        errorModal: true
      });

      // console.log(this.dataToSave);
    }
  }

  retry = () => {
    this.setState({
      uploadingStatus: 'uploading',
      errorModal: false
    }, () => {
      let data = {...this.dataToSave};

      if (data.data && data.extraData) {
        this.pushData(data.data, data.extraData, 0);
      } else {
        console.log('skipping retry');
        this.setState({
          uploadingStatus: '',
          errorModal: false
        });
      }
    });
  }

  handleModalClose = () => {
    this.dataToSave = null;
    this.setState({
      errorModal: false,
      uploadingStatus: ''
    });
  }

  resetFullData() {
    this.setState({
      patient: this.cleanPatientData(),
      cleanFlow: true,
      termsNConditions: false
    });
  }

  toggleTermsNConditionsHandler = () => {
    let termsNConditions = !this.state.termsNConditions;
    let options = {...this.state.options};
    let nextOption = {...options.next};
    let previousOption = {...options.previous};
    let labelOption = {...options.label};

    if (termsNConditions) {
      nextOption.disabled = false;
      previousOption.disabled = true;
      // previousOption.visible = true;
    } else {
      nextOption.disabled = true;
      previousOption.disabled = true;
      // previousOption.visible = false;
    }

    labelOption.visible = false;
    

    options.next = nextOption;
    options.previous = previousOption;
    options.label = labelOption;

    this.setState({
      termsNConditions: !this.state.termsNConditions,
      options: options
    })
  }

  updatePatientProperty = (property, value, section) => {
    let patient = {...this.state.patient};
    
    if (patient.hasOwnProperty(property)) {
      if (property !== 'birthday') {
        patient[property] = value;
      } else {
        let birthdayExtended = {...patient.birthdayExtended};
        birthdayExtended[section] = value;

        if (birthdayExtended.day && birthdayExtended.month && birthdayExtended.year) {
          let birthday = dayjs(
            birthdayExtended.year + '-' + birthdayExtended.month + '-' + birthdayExtended.day,
            'YYYY-M-D',
            true
          );

          if (birthday.isValid()) {
            patient.birthday = birthday.toISOString();
            patient.age = dayjs().diff(birthday, 'year');
          } else {
            patient.birthday = '';
            patient.age = null;
          }
        } else {
          patient.birthday = '';
          patient.age = null;
        }

        patient.birthdayExtended = birthdayExtended;
      }

      if (property !== 'verificatedData') {
        patient.verificatedData = false;
      }

      this.setState({
        patient: patient
      }, () => {
        // if (this.state)
        // moveForwardPatientView
        if (property && property === 'gender' && value) {
          this.moveForwardPatientView();
        } else {
          this.checkNextPropertyEnableForPatient();
        }        
      });
    }
  }

  onKeyEnterFillingPatientInfo = () => {
    this.checkNextPropertyEnableForPatient(() => {
      let options = {...this.state.options};
      let nextOption = {...options.next};

      if (!nextOption.disabled) {
        this.moveForwardPatientView();
      }
    })
  }

  checkNextPropertyEnableForPatient(moveForwardPatientCB) {
    let patient = {...this.state.patient};

    let sections = [...this.state.sections];
    let patientSectionIndex = sections.findIndex(section => section.key === 'patient');
    let patientSection = {...sections[patientSectionIndex]};

    let options = {...this.state.options};
    let nextOption = {...options.next};
    // let previousOption = {...options.previous};
    // let labelOption = {...options.label};

    let enabled = false;
    let updated = false;

    switch (patientSection.view) {
      case 'patient_basic': {
        enabled = patient.name && patient.lastName;
        updated = true;
        break;
      }
      case 'gender': {
        enabled = !!patient.gender;
        updated = true;
        break;
      }
      case 'birthday': {
        enabled = !!patient.birthday;
        updated = true;
        break;
      }
      case 'ci_phone': {
        enabled = patient.phone && patient.ci
        updated = true;
        break;
      }
      case 'insurance_data': {
        enabled = !!patient.extraPoliceUnit; // patient.insuranceNumberId && patient.armyNumberId;
        updated = true;

        setTimeout(() => {
          this.getPoliceUnits();
        })
        break;
      }
      case 'verification': {
        enabled = !!patient.verificatedData;
        updated = true;
        break;
      }
      default:
        break;
    }

    if (updated) {
      nextOption.disabled = !enabled;
      options.next = nextOption;

      this.setState({
        options: options
      }, () => {
        if (moveForwardPatientCB) {
          moveForwardPatientCB();
        } 
      });
    } else {
      if (moveForwardPatientCB) {
        moveForwardPatientCB();
      }
    }
  }

  getPoliceUnits = () => {
    this.state.connection.getPoliceUnits().then((response) => {
      let data = response.data || [];
      let patient = {...this.state.patient};
      patient.policeUnits = data;
      this.setState({
        patient: patient
      });
    })
  }

  getDiagnosis = () => {
    let patient = {...this.state.patient};
    let sections = [...this.state.sections]
    let symtomsSectionIndex = sections.findIndex(section => section.key === 'symptoms');
    let symtomsSection = {...sections[symtomsSectionIndex]};
    let options = {...this.state.options};
    let nextOption = {...options.next};

    nextOption.disabled = true;
    options.next = nextOption;

    symtomsSection.loadingQuestion = true;
    sections[symtomsSectionIndex] = symtomsSection;

    let data = {
      sex: patient.gender,
      age: patient.age,
      evidence: patient.evidence
    };

    this.setState({
      sections: sections,
      options: options
    });

    this.state.connection.getDiagnosis(data).then((response) => {
      // console.log('response', response);
      let question = response.data;

      let patient = {...this.state.patient};
      let sections = [...this.state.sections]
      let symtomsSectionIndex = sections.findIndex(section => section.key === 'symptoms');
      let symtomsSection = {...sections[symtomsSectionIndex]};

      let options = {...this.state.options};
      let nextOption = {...options.next};

      if (question && question.question && question.question.items && question.question.items.length) {
        let items = question.question.items;
        let newEvidence = [];
        let areThereItemsEmpty = false;
        for (let i = 0; i < items.length; i++) {
          let item = items[i];

          let groupMultipleExplicit = item.id === 's_0' ||
            item.id === 's_1' ||
            item.id === 's_2'

          let itemEmpty = question.question.type === 'single' || groupMultipleExplicit;

          // saving in a outside for loop a variable that says if there was found and item that
          // it's starting with empty value

          areThereItemsEmpty = areThereItemsEmpty || itemEmpty;

          newEvidence.push({
            id: item.id,
            choice_id: itemEmpty ? '' : 'absent'
          });

          question.question.explicit = groupMultipleExplicit;
        }

        patient.evidence = patient.evidence.concat(newEvidence);

        symtomsSection.questions.push(question);
        symtomsSection.questionIndex = symtomsSection.questions.length;
        symtomsSection.loadingQuestion = false;

        nextOption.disabled = question.should_stop ||
          question.question.type === 'group_single' ||
          question.question.type === 'single' ||
          areThereItemsEmpty;

        sections[symtomsSectionIndex] = symtomsSection;
        options.next = nextOption;

        this.setState({
          patient: patient,
          sections: sections,
          options: options
        });
      } else if (!question  || !question.question) {
        this.moveToResultSection();
      }      
    }, () => {

    });
  }

  scrollToBottom() {
    var content = document.querySelector('.panel-content');

    if (content) {
      content.scrollTop = content.scrollHeight;
    }
  }

  render() {
    let sectionIndexSelected = this.state.sections.findIndex(section => section.active);
    // console.log('sectionIndexSelected', sectionIndexSelected);
    // console.log(this.state)
    let section = this.state.sections[sectionIndexSelected];

    let renderedSection;

    let whatDoSection;
    let resultDisclaimerSection;

    if (section.key === 'introduction') {
      renderedSection = (
        <Introduction  
          termsChangeHandler={this.toggleTermsNConditionsHandler}
          termsNConditions={this.state.termsNConditions}/>
      )
    } else if (section.key === 'patient') {
      renderedSection = (
        <Patient
          patient={this.state.patient}
          section={section}
          updatePatientProperty={this.updatePatientProperty}
          onKeyEnter={this.onKeyEnterFillingPatientInfo}
        />
      )
    } else if (section.key === 'symptoms') {
      renderedSection = (
        <Symptoms
          patient={this.state.patient}
          section={section}
          saveEvidence={this.saveEvidence}
        />
      )
    } else if (section.key === 'result') {
      renderedSection = (
        <Result
          section={section}
          reDoTest={this.moveToIntroductionSection}
        />
      );

      if (!section.loadingResult) {
        whatDoSection = (
          <div className="what-do-content">
            <WhatToDo
              section={section}
            />
          </div>
        );
  
        resultDisclaimerSection = (
          <div className="result-disclaimer">
            Tenga en cuenta que este resultado solo tiene efectos informativos y formativos y en ningún caso debe interpretarse ni tratarse como un consejo, consulta o diagnóstico de un médico.
          </div>
        );
      }
    }

    let footerOptions = [];

    if (this.state.options.previous.visible) {
      footerOptions.push(
        <Button 
          key="previous-button"
          variant="contained"
          color="primary"
          onClick={this.previousSectionHandler}
          disabled={this.state.options.previous.disabled}>
          Atrás
        </Button>
      )
    }

    if (this.state.options.next.visible) {
      footerOptions.push(
        <Button
          className="green-button"
          key="next-button"
          variant="contained"
          color="primary"
          onClick={this.nextSectionHandler}
          disabled={this.state.options.next.disabled}>
          Siguiente
        </Button>
      )
    }

    if (this.state.options.label.visible) {
      footerOptions.push(
        <div key="label">
          {this.state.options.label.text}
        </div>
      )
    }

    let classLoading = section.key === 'result' && section.loadingResult ? ' full-heigth' : '';
    
    return (
      <div className="layout">
        <Header uploadingStatus={this.state.uploadingStatus}></Header>
        <SimpleModal
          open={this.state.errorModal}
          close={this.handleModalClose}
          retry={this.retry}/>
        <div className="layout-view-container">
          <Sidebar
            sections={this.state.sections}
            sectionChangeHandler={this.sectionChangeHandler}></Sidebar>
          <div className={"panel-container " + section.key}>
            <div>
              <div className={"panel-content" + classLoading}>
                {renderedSection}  
              </div>
              {whatDoSection}
              {resultDisclaimerSection}
              <div className="footer-content">
                {footerOptions}
              </div>
              <div
                onClick={this.scrollToBottom}
                className="arrow-option">
                <VerticalAlignBottomIcon fontSize="small"/>
              </div>
            </div>
          </div>
        </div>
      </div>   
    );
  }
}

export default Layout;
