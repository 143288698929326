import axios from 'axios';

// console.log(process.env);

const triage_weights = {
  no_risk: 1,
  self_monitoring: 2,
  quarantine: 3,
  isolation_call: 4,
  call_doctor: 5,
  isolation_ambulance: 6
};

const triage_level_i18n = {
  no_risk: 'Sin riesgo',
  self_monitoring: 'Riesgo bajo',
  quarantine: 'Riesgo medio - cuarentena',
  isolation_call: 'Riesgo medio',
  call_doctor: 'Riesgo medio',
  isolation_ambulance: 'Riesgo muy alto'
};

const triage_risk = {
  no_risk: 'low_risk',
  self_monitoring: 'low_risk',
  quarantine: 'middle_risk',
  isolation_call: 'middle_risk',
  call_doctor: 'middle_risk',
  isolation_ambulance: 'high_risk'
};

class Connection {
  smdHost = process.env.REACT_APP_SERVER_HOST;
  smdApiKey = process.env.REACT_APP_APIKEY;
  triageHost = process.env.REACT_APP_TRIAGE_SERVER_HOST;
  appId = process.env.REACT_APP_TRIAGE_APPID;
  appKey = process.env.REACT_APP_TRIAGE_APPKEY;
  templateKey = process.env.REACT_APP_TEMPLATEKEY

  userId = new Date().getTime().toString()

  endpoints = {
    diagnosis: '/diagnosis',
    triage: '/triage',
    patient: '/patients/triage'
  }

  now;

  encodeUUID = (input) => {
    this.now = new Date().getTime();
    return (parseInt(input) + this.now).toString(15);
  }

  reviewCache = (successCB) => {
    // var lastModified = window.localStorage.getItem('last-modified')

    var cachedHeaders = {
      'last-modified': window.localStorage.getItem('last-modified'),
      'etag': window.localStorage.getItem('etag'),
      'ts': new Date().getTime()
    };

    var baseHeaders = {};

    if (cachedHeaders['last-modified']) {  //set the required headers to get 304 if file not changed
      baseHeaders['If-Modified-Since'] = cachedHeaders['last-modified'];
      baseHeaders['If-None-Match'] = cachedHeaders['etag'];
    }

    baseHeaders['ts'] = new Date().getTime().toString();

    axios({
      method: 'get',
      headers: baseHeaders,
      responseType: 'text',
      url: 'index.html'
    }).then((response) => {
      let indexReload = false;
      let responseHeaders = response.headers;

      let indexETag = cachedHeaders['etag']
      let eTag = responseHeaders['etag'];
      let lastModifed = responseHeaders['last-modified'];

      if (indexETag && indexETag !== eTag) {
        indexReload = true;
      }

      localStorage.setItem('last-modified', lastModifed);
      localStorage.setItem('etag', eTag);

      if (indexReload) {
        window.location.reload();
        console.log('reloading app to clean cache');
      } else {
        if (successCB && typeof successCB === 'function') {
          successCB();
        }
      }
    }, () => {
      if (successCB && typeof successCB === 'function') {
        successCB();
      }
    });
  }

  getDiagnosis(data) {
    data = {...data}
    data.age = Math.max(data.age, 18);

    return axios({
      method: 'post',
      url: this.triageHost + this.endpoints.diagnosis,
      headers: {
        'App-Id': this.appId,
        'App-Key': this.appKey,
        'Content-Type': 'application/json',
        'Model': 'infermedica-es'
      },
      data: data
    });
  }

  getTriage(data) {
    return axios({
      method: 'post',
      url: this.triageHost + this.endpoints.triage,
      headers: {
        'App-Id': this.appId,
        'App-Key': this.appKey,
        'Content-Type': 'application/json',
        'Model': 'infermedica-es'
      },
      data: data
    });
  }

  getPoliceUnits() {
    return axios({
      method: 'get',
      url: 'https://dl.dropboxusercontent.com/s/tbvag05mq6dbz8m/policeUnits.json?dl=0'
    });
  }

  submitToSMD(data, extraData) {
    data = {...data};
    extraData = {...extraData};
    
    let triage = {...data.triage};
    triage.templateKey = this.templateKey
    triage.title = 'Prediagnóstico COVID-19 (' + triage_level_i18n[triage.triage_level] + ')';
    triage.extra = {
      triage_level: triage.triage_level,
      triage_weight: triage_weights[triage.triage_level] || 0,
      triage_risk: triage_risk[triage.triage_level] || '',
      treated: false,
      policeUnit: extraData.extraPoliceUnit
    };

    data.triage = triage;

    var uuid = this.encodeUUID(data.personID);
    data.createdDate = new Date(this.now).toISOString();
    triage.extra['triageDate'] = new Date(this.now).toISOString();

    return axios({
      method: 'post',
      url: this.smdHost + this.endpoints.patient,
      headers: {
        'apiKey': this.smdApiKey,
        'Content-Type': 'application/json',
        'uuid': uuid
      },
      data: data
    });
  }
}

export default Connection;