import React /*, { useEffect }*/ from 'react';
import './App.scss';
import Layout from './components/Layout/Layout';

function App() {
  // useEffect(() => {
  //   console.log('mount');

  //   function handleResize() {
  //     // Set window width/height to state
  //     checkScrollableContent();
  //   }
    
  //   // Add event listener
  //   window.addEventListener("resize", handleResize);
    
  //   // Call handler right away so state gets updated with initial window size
  //   handleResize();

  //   return () => { 
  //     console.log('before unmounting');      
  //   }
  // }, [] );

  // const checkScrollableContent = (first) => {
  //   // console.log('resizing');
  //   // let vh = window.innerHeight * 0.01;
  //   // document.documentElement.style.setProperty('--vh', `${vh}px`);

  //   const doc = document.documentElement
  //   doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  // }

  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
